import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import ProjectPage from "./pages/dashboard";
import ProjectFiles from "./pages/project";
import FileUpload from "./pages/upload";
import NotFound from "./pages/404";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<ProjectPage />} />
        <Route path="/project/:projectId" element={<ProjectFiles />} />
        <Route path="/upload/:projectId" element={<FileUpload />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
import React from "react";

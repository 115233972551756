import React from "react";
import styles from "../css/footer.module.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <div className={styles.container}>
        <hr className={styles.line} />
        <p className={styles.text}>
          &copy; {new Date().getFullYear()} Sharebox. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CircularProgress, Container, Grid, Button } from "@mui/material";
import { Table } from "@mui/joy";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import Footer from "../components/footer";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://sharebox.wojianw.com";

interface File {
  id: string;
  name: string;
  is_public: boolean;
  owner_id: string;
  project_id: string;
}

const ProjectFiles: React.FC = () => {
  const navi = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const token = localStorage.getItem("jwt_token");

  if (!token) {
    navi("/");
    return null;
  }

  const authHeader = { headers: { Authorization: `Bearer ${token}` } };

  const truncateFilename = (name: string, maxLength: number = 40) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "...";
    }
    return name;
  };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(
          `/api/file/list/${projectId}`,
          authHeader,
        );
        setFiles(response.data);
      } catch (err) {
        if (
          axios.isAxiosError(err) &&
          (err.response?.status === 401 || err.response?.status === 404)
        ) {
          navi("/");
        } else {
          console.error("Failed to fetch files", err);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchFiles();
  }, [projectId, navi]);

  const toggleVisibility = async (fileId: string) => {
    try {
      const fileToUpdate = files.find((file) => file.id === fileId);
      if (!fileToUpdate) return;

      const is_public = !fileToUpdate.is_public;
      await axios.get(
        `/api/file/set-public/?id=${fileId}&is_share=${is_public}`,
        authHeader,
      );
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId ? { ...file, is_public: is_public } : file,
        ),
      );
    } catch (err) {
      setError("Could not update visibility.");
    }
  };

  const deleteFile = async (fileId: string) => {
    try {
      await axios.delete(`/api/file/${fileId}`, authHeader);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
    } catch (err) {
      setError("Could not delete the file.");
    }
  };

  const copyDownloadLink = (fileId: string) => {
    const downloadLink = `${BASE_URL}/api/file/download/${fileId}`;
    navigator.clipboard
      .writeText(downloadLink)
      .then(() => {
        alert("Download link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setError("Could not copy the download link.");
      });
  };
  //
  const downloadFile = async (fileId: string) => {
    try {
      const downloadLink = `/api/file/download/${projectId}/${fileId}?token=${token}`;
      window.open(downloadLink, "_blank");
    } catch (error) {
      console.error("File download failed", error);
      setError("Could not download the file.");
    }
  };

  return (
    <Container style={{ minHeight: "100vh", paddingBottom: "50px" }}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            style={{
              marginBottom: "50px",
              marginTop: "50px",
              justifyContent: "center",
              width: "70%",
            }}
          >
            <Grid item>
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="primary">
                  Go Back
                </Button>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to={`/upload/${projectId}`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="outlined" color="secondary">
                  Upload
                </Button>
              </Link>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "50px",
            }}
          >
            <Table
              sx={{
                borderRadius: "16px",
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                width: "70%",
                fontFamily: "'Source Code Pro', monospace",
                "& th, & td": {
                  fontFamily: "'Source Code Pro', monospace",
                },
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f5f5f5", height: "48px" }}>
                  <th
                    style={{ padding: "16px", textAlign: "left", width: "60%" }}
                  >
                    Name
                  </th>
                  <th style={{ padding: "16px", width: "13%" }}>Visibility</th>
                  <th style={{ padding: "16px", width: "13%" }}></th>
                  <th style={{ padding: "16px", width: "13%" }}></th>
                </tr>
              </thead>
              <tbody>
                {files.map((file) => (
                  <tr
                    key={file.id}
                    style={{
                      cursor: "pointer",
                      transition: "background-color 0.2s",
                    }}
                  >
                    <td style={{ padding: "16px", width: "70%" }}>
                      <span
                        onClick={() => downloadFile(file.id)} // Change this to call downloadFile
                        style={{
                          textDecoration: "none",
                          color: "#8064A2",
                          cursor: "pointer",
                        }}
                      >
                        {truncateFilename(file.name)}
                      </span>
                    </td>
                    <td style={{ padding: "16px", width: "10%" }}>
                      <Button
                        variant="outlined"
                        color={file.is_public ? "success" : "info"}
                        onClick={() => toggleVisibility(file.id)}
                      >
                        {file.is_public ? "Public" : "Private"}
                      </Button>
                    </td>
                    <td style={{ padding: "16px", width: "10%" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => copyDownloadLink(file.id)}
                        disabled={!file.is_public} // Disable if not public
                        style={{ marginLeft: "8px" }}
                      >
                        Copy Link
                      </Button>
                    </td>
                    <td style={{ padding: "16px", width: "10%" }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => deleteFile(file.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Footer />
        </>
      )}
    </Container>
  );
};

export default ProjectFiles;

import React, { Component } from "react";
import Footer from "../components/footer";
import styles from "../css/home.module.css";

interface AppState {
  producerLoginRedirectEndpoint: string;
  userLoggedIn: boolean;
  userName: string | null;
}

class Home extends Component {
  state: AppState = {
    producerLoginRedirectEndpoint: "/api/login-redirect", // Endpoint to start OAuth flow
    userLoggedIn: false,
    userName: null,
  };

  componentDidMount() {
    this.authenticate();
  }

  // store returned jwt info
  authenticate = () => {
    const token = this.getQueryParam("token");
    const email = this.getQueryParam("email");
    window.history.pushState("object", document.title, "/");

    if (token) {
      localStorage.setItem("jwt_token", token);
      this.setState(
        {
          userLoggedIn: true,
          userName: email || null,
        },
        () => {
          // Redirect to the Dashboard page after setting state
          window.location.href = "/dashboard";
        },
      );
    }
  };

  getQueryParam = (param: string): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  render() {
    return (
      <Login
        producerLoginRedirectEndpoint={this.state.producerLoginRedirectEndpoint}
      />
    );
  }
}

const Login: React.FC<{ producerLoginRedirectEndpoint: string }> = (props) => {
  const googleLogin = () => {
    window.location.href = props.producerLoginRedirectEndpoint; // Redirect to start Google login
  };

  return (
    <section id={styles.pagecontainer}>
      <h1 className={styles.header}>Sharebox</h1>
      <p className={styles.description}>Click, Share, Collect</p>
      <div id={styles.buttoncontainer}>
        <button className={styles["home-button"]} onClick={googleLogin}>
          Login with Google
        </button>
      </div>
      <Footer />
    </section>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Grid,
  Button,
  TextField,
  CircularProgress,
  Container,
} from "@mui/material";
import { Table } from "@mui/joy";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import Footer from "../components/footer";

interface Project {
  id: string;
  name: string;
  description: string;
  owner_id: string;
}

const ProjectPage: React.FC = () => {
  const navi = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [newProjectName, setNewProjectName] = useState("");
  const [newProjectDescription, setNewProjectDescription] = useState("");
  const token = localStorage.getItem("jwt_token");

  if (!token) {
    navi("/");
    return null;
  }

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/api/project/list/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjects(response.data);
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          (error.response?.status === 401 || error.response?.status === 404)
        ) {
          navi("/");
        } else {
          console.error("Failed to fetch projects", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [navi]);

  const deleteProject = async (id: string) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this project?",
    );
    if (confirmed) {
      try {
        await axios.delete(`/api/project/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.id !== id),
        );
      } catch (error) {
        console.error("Failed to delete project", error);
      }
    }
  };

  const createProject = async () => {
    const newProject = {
      id: uuidv4(),
      name: newProjectName,
      description: newProjectDescription,
      owner_id: "",
    };

    try {
      const response = await axios.post("/api/project/create/", newProject, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setProjects((prevProjects) => [...prevProjects, response.data]);

      // clear input
      setNewProjectName("");
      setNewProjectDescription("");
    } catch (error) {
      console.error("Failed to create project", error);
    }
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        style={{
          marginBottom: "50px",
          marginTop: "50px",
          justifyContent: "center",
        }}
      >
        <Grid item>
          <TextField
            label="Project Name"
            variant="outlined"
            size="small"
            value={newProjectName}
            onChange={(e) => setNewProjectName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Project Description"
            variant="outlined"
            size="small"
            value={newProjectDescription}
            onChange={(e) => setNewProjectDescription(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" onClick={createProject}>
            Create Project
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <CircularProgress />
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <Table
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              width: "70%",
              fontFamily: "'Source Code Pro', monospace",
              "& th, & td": {
                fontFamily: "'Source Code Pro', monospace",
              },
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f5f5f5", height: "48px" }}>
                <th style={{ padding: "16px", textAlign: "left" }}>Name</th>
                <th style={{ padding: "16px", textAlign: "left" }}>
                  Description
                </th>
                <th style={{ padding: "16px" }}></th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr
                  key={project.id}
                  style={{
                    cursor: "pointer",
                    transition: "background-color 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#fafafa")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                >
                  <td style={{ padding: "16px" }}>
                    <Link
                      to={`/project/${project.id}`}
                      style={{ textDecoration: "none", color: "#8064A2" }}
                    >
                      {project.name}
                    </Link>
                  </td>
                  <td style={{ padding: "16px" }}>{project.description}</td>
                  <td style={{ padding: "16px" }}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => deleteProject(project.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <Footer />
    </Container>
  );
};

export default ProjectPage;

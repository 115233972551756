import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AxiosRequestConfig, AxiosProgressEvent } from "axios";
import {
  Container,
  Grid,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Table } from "@mui/joy";
import Footer from "../components/footer";

interface FileDetails {
  file: File;
  size: number;
  type: string;
  uploading: boolean;
}

const FileUpload: React.FC = () => {
  const navi = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();
  const [files, setFiles] = useState<FileDetails[]>([]);
  const [uploadingAll, setUploadingAll] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: number;
  }>({});

  const truncateFilename = (name: string, maxLength: number = 40) => {
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "...";
    }
    return name;
  };

  useEffect(() => {
    const checkProjectExists = async () => {
      if (!projectId) {
        navi("/404");
        return;
      }

      try {
        const response = await axios.get(`/api/project/${projectId}`);
      } catch (error) {
        console.error("Project not found:", error);
        navi("/404");
      }
    };
    checkProjectExists();
  }, [projectId, navi]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesWithDetails: FileDetails[] = acceptedFiles.map((file) => ({
      file,
      size: file.size,
      type: file.type,
      uploading: false,
    }));
    setFiles((prevFiles) => [...prevFiles, ...filesWithDetails]);
  }, []);

  const uploadFile = async (fileObj: FileDetails) => {
    const formData = new FormData();
    if (!projectId) {
      console.log("projectId is undefined");
      return null;
    }

    formData.append("name", fileObj.file.name);
    formData.append("file_size", fileObj.size.toString());
    formData.append("mime_type", fileObj.type);
    formData.append("project_id", projectId);
    formData.append("is_public", "false");
    formData.append("file", fileObj.file);

    setUploadProgress((prev) => ({ ...prev, [fileObj.file.name]: 0 }));

    const config: AxiosRequestConfig<FormData> = {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 1),
        );
        setUploadProgress((prev) => ({
          ...prev,
          [fileObj.file.name]: progress,
        }));
      },
    };

    try {
      const response = await axios.post("/api/file/upload", formData, config);
      console.log("File uploaded successfully:", response.data);

      setFiles((prev) =>
        prev.map((f) =>
          f.file.name === fileObj.file.name ? { ...f, uploading: false } : f,
        ),
      );
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleUpload = (fileObj: FileDetails) => {
    uploadFile(fileObj);
    setFiles((prev) =>
      prev.map((f) =>
        f.file.name === fileObj.file.name ? { ...f, uploading: true } : f,
      ),
    );
  };

  const handleUploadAll = () => {
    setUploadingAll(true);
    Promise.all(files.filter((f) => !f.uploading).map(handleUpload))
      .then(() => {
        setUploadingAll(false);
      })
      .catch(() => {
        setUploadingAll(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const tableData = files.map((file) => ({
    "File Name": truncateFilename(file.file.name),
    "File Size (bytes)": file.size,
    "File Type": file.type,
    Action: !file.uploading ? (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleUpload(file)}
      >
        Upload
      </Button>
    ) : (
      "Uploading..."
    ),
    "Upload Progress": (
      <div
        style={{ width: "100%", background: "#f3f3f3", borderRadius: "5px" }}
      >
        <div
          style={{
            width: `${uploadProgress[file.file.name] || 0}%`,
            height: "10px",
            background: "#4caf50",
            borderRadius: "5px",
          }}
        />
      </div>
    ),
  }));

  return (
    <Container style={{ width: "70%" }}>
      <div {...getRootProps({ className: "dropzone" })} style={dropzoneStyle}>
        <input {...getInputProps()} />
        <Typography
          variant="body1"
          style={{ fontFamily: "'Source Code Pro', monospace" }}
        >
          Drag & drop files here, or click to select
        </Typography>
      </div>
      <div style={{ height: "70px" }}>
        <Button
          variant="outlined"
          color="secondary"
          disabled={uploadingAll || files.every((file) => file.uploading)}
          onClick={handleUploadAll}
        >
          {uploadingAll ? <CircularProgress size={24} /> : "Upload All"}
        </Button>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            sx={{
              borderRadius: "16px",
              overflow: "hidden",
              boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              width: "100%",
              fontFamily: "'Source Code Pro', monospace",
              "& th, & td": {
                fontFamily: "'Source Code Pro', monospace",
              },
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#f5f5f5", height: "48px" }}>
                <th
                  style={{ padding: "16px", textAlign: "left", width: "40%" }}
                >
                  File Name
                </th>
                <th style={{ padding: "16px", width: "15%" }}>Size</th>
                <th style={{ padding: "16px", width: "15%" }}>Type</th>
                <th style={{ padding: "16px", width: "15%" }}></th>
                <th style={{ padding: "16px", width: "15%" }}>Progress</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    cursor: "pointer",
                    transition: "background-color 0.2s",
                  }}
                >
                  <td style={{ padding: "16px", width: "40%" }}>
                    {row["File Name"]}
                  </td>
                  <td style={{ padding: "16px", width: "15%" }}>
                    {row["File Size (bytes)"]}
                  </td>
                  <td style={{ padding: "16px", width: "15%" }}>
                    {row["File Type"]}
                  </td>
                  <td style={{ padding: "16px", width: "15%" }}>
                    {row.Action}
                  </td>
                  <td style={{ padding: "16px", width: "15%" }}>
                    {row["Upload Progress"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccccc",
  borderRadius: "16px",
  padding: "20px",
  textAlign: "center",
  marginBottom: "20px",
  marginTop: "40px",
  cursor: "pointer",
};

export default FileUpload;
